import * as React from "react";

const PaymentEmptyIcon = (props: React.SVGProps<any>) => (
  <svg
    width={250}
    height={200}
    viewBox='0 0 250 200'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width={250} height={200} fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M63 134H154C154.515 134 155.017 133.944 155.5 133.839C155.983 133.944 156.485 134 157 134H209C212.866 134 216 130.866 216 127C216 123.134 212.866 120 209 120H203C199.134 120 196 116.866 196 113C196 109.134 199.134 106 203 106H222C225.866 106 229 102.866 229 99C229 95.134 225.866 92 222 92H200C203.866 92 207 88.866 207 85C207 81.134 203.866 78 200 78H136C139.866 78 143 74.866 143 71C143 67.134 139.866 64 136 64H79C75.134 64 72 67.134 72 71C72 74.866 75.134 78 79 78H39C35.134 78 32 81.134 32 85C32 88.866 35.134 92 39 92H64C67.866 92 71 95.134 71 99C71 102.866 67.866 106 64 106H24C20.134 106 17 109.134 17 113C17 116.866 20.134 120 24 120H63C59.134 120 56 123.134 56 127C56 130.866 59.134 134 63 134ZM226 134C229.866 134 233 130.866 233 127C233 123.134 229.866 120 226 120C222.134 120 219 123.134 219 127C219 130.866 222.134 134 226 134Z'
      fill='#F3F7FF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.9525 70.5176L152.977 57.2259C154.613 56.964 156.152 58.0779 156.414 59.714C156.414 59.7172 156.415 59.7205 156.415 59.7238L164.537 111.556C164.793 113.189 163.68 114.721 162.048 114.983L79.0227 128.274C77.3867 128.536 75.8481 127.422 75.5862 125.786C75.5857 125.783 75.5851 125.78 75.5846 125.776L67.4629 73.9443C67.207 72.3113 68.3203 70.7789 69.9525 70.5176Z'
      fill='white'
    />
    <path
      d='M83.3448 127.423L80.1615 127.948C77.9748 128.308 75.9214 126.756 75.575 124.481L68.0499 75.0391C67.7036 72.7636 69.1955 70.6268 71.3822 70.2664L151.558 57.0519C153.745 56.6916 155.798 58.2441 156.145 60.5196C156.482 62.7367 156.751 64.5036 156.952 65.8203M157.523 69.5758C157.705 70.7685 157.864 71.8129 158 72.7091'
      stroke='#1F64E7'
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M73.977 72.792L149.737 60.9609C151.369 60.7061 152.9 61.8183 153.162 63.4492L160.522 109.268C160.785 110.904 159.672 112.443 158.036 112.706C158.032 112.707 158.027 112.707 158.023 112.708L82.2629 124.539C80.631 124.794 79.1 123.682 78.838 122.051L71.4779 76.2319C71.2151 74.596 72.3282 73.0568 73.9641 72.794C73.9684 72.7933 73.9727 72.7927 73.977 72.792Z'
      fill='#E8F0FE'
    />
    <path
      d='M170.75 77.25H92.25C90.0409 77.25 88.25 79.0409 88.25 81.25V129.75C88.25 131.959 90.0409 133.75 92.25 133.75H170.75C172.959 133.75 174.75 131.959 174.75 129.75V81.25C174.75 79.0409 172.959 77.25 170.75 77.25Z'
      fill='white'
      stroke='#1F64E7'
      strokeWidth='2.5'
    />
    <path d='M173.5 89H89.5V103H173.5V89Z' fill='#E8F0FE' />
    <path
      d='M103.289 89H96.0926M174.171 89H108.331H174.171ZM168.171 102H89.3315H168.171ZM125.062 121H96.7546H125.062Z'
      stroke='#1F64E7'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default PaymentEmptyIcon;
